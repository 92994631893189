<template>
  <div class="module7">
    <div class="page0-title">
      <span style="display: flex; align-items: center;"
        ><img
          class="title-icon"
          src="../../../assets/images/title-icon.png"
          alt=""
          srcset=""
        />平台车辆统计</span
      >
    </div>

    <div class="module7-content">
      <div
        class="module7-content-item"
        v-for="(item, index) in list"
        :key="item.name"
      >
        <div class="module7-content-item-title">
          <span>{{ item.name }}</span>
          <span>{{ item.number }}</span>
        </div>
        <LineBar
          :ref="`lineBar${index}`"
          :color="item.color"
          :activeColor="item.activeColor"
          :rate="item.rate"
        />
      </div>
    </div>

    <span class="angle1"></span>
    <span class="angle2"></span>
    <span class="angle3"></span>
    <span class="angle4"></span>
  </div>
</template>

<script>
import LineBar from "./line-bar.vue";
export default {
  data() {
    return {
      list: [
        {
          name: "半挂",
          number: '250,816',
          rate: 70,
          color: "#047CFF",
          activeColor: "#20A6FF",
        },
        {
          name: "货车",
          number: '51,020',
          rate: 63,
          activeColor: "#00F9EE",
          color: "#00BBB3",
        },
        {
          name: "工程车",
          number: '8,710',
          rate: 40,
          color: "#047CFF",
          activeColor: "#20A6FF",
        },
        {
          name: "危化车",
          number: '4,560',
          rate: 35,
          activeColor: "#00F9EE",
          color: "#00BBB3",
        },
        {
          name: "箱货",
          number: '25,010',
          rate: 34,
          color: "#047CFF",
          activeColor: "#20A6FF",
        },
        {
          name: "特种车",
          number: '2,780',
          rate: 30,
          activeColor: "#00F9EE",
          color: "#00BBB3",
        },
        {
          name: "冷链",
          number: '3,897',
          rate: 20,
          color: "#047CFF",
          activeColor: "#20A6FF",
        },
        {
          name: "其他",
          number: '7,432',
          rate: 12,
          activeColor: "#00F9EE",
          color: "#00BBB3",
        },
        {
          name: "合计",
          number: '354,225',
          rate: 100,
          color: "#047CFF",
          activeColor: "#20A6FF",
        },
      ],
    };
  },
  components: {
    LineBar,
  },
  computed: {},
  methods: {
    setChart() {
      this.list.forEach((item, index) => {
        console.log(
          this.$refs[`lineBar${index}`].setChart,
          this.$refs[`lineBar${index}`]
        );
        this.$refs[`lineBar${index}`][0] && this.$refs[`lineBar${index}`][0].setChart &&
          this.$refs[`lineBar${index}`][0].setChart();
      });
    },
  },
  mounted() {
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.module7 {
  position: relative;
  border: 1px solid #0d2451;
  height: 100%;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(60px);

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 3px 10px;
    flex: 1;

    &-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 1px 0;
      &-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1px;
        color: #ffffff;
        font-size: 13px;
      }

      &-top {
        color: #ffffff;
        font-size: 22px;
        font-weight: 900;
      }
      &-bottom {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
</style>
