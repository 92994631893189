<template>
  <div class="line-bar" :style="barStyle">
    <div
      class="line-bar-active"
      :style="activeStyle"
      v-for="(item, index) in activeArr"
      :key="index"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#047CFF",
    },
    activeColor: {
      type: String,
      default: "#20A6FF",
    },
    rate: {
      type: Number,
      default: 80,
    },
  },
  data() {
    return {
      width: 0,
      activeArr: [],
      timer: null,
    };
  },
  computed: {
    barStyle() {
      return {
        "background-color": this.color,
        border: `solid 2px ${this.color}`,
      };
    },
    activeStyle() {
      return {
        "background-color": this.activeColor,
        "border-right": `solid 2px ${this.color}`,
      };
    },
    maxArrLength() {
      const length = Math.round(((this.width / 100) * this.rate) / 5);
      let result = [];
      for (let index = 0; index <= length; index++) {
        result.push({});
      }
      return result;
    },
  },
  methods: {
    getWidth() {
      const bar = document.getElementsByClassName("line-bar")[0];
      this.width = bar.clientWidth;
    },
    setChart() {
      this.activeArr = [];
      const tervalStep = Math.round(this.maxArrLength.length / 20);

      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(() => {
        if (this.activeArr.length >= this.maxArrLength.length) {
          return clearInterval(this.timer);
        } else {
          for (let index = 0; index < tervalStep; index++) {
            this.activeArr.push({});
          }
        }
      }, 50);
    },
  },
  mounted() {
    this.getWidth();
    this.setChart();
  },
};
</script>

<style lang="less" scoped>
.line-bar {
  width: 100%;
  height: 9px;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  opacity: 1;

  &-active {
    width: 5px;
    height: 5px;
    box-sizing: border-box;
    opacity: 1;
  }
}
</style>
