<template>
  <div class="page0">
    <Row style="height:100%">
      <Col span="7" style="height:100%">
        <div style="height: 44%;">
          <Module1 ref="chart1" />
        </div>
        <div style="height: 55%;">
          <div style="padding:6px 0;height:100%">
            <Module2 ref="chart2" />
          </div>
        </div>
        <!-- <div style="height: 25%;">
          <div style="height:100%">
            <Module3 ref="chart3" />
          </div>
        </div> -->
      </Col>
      <Col span="10" style="height:100%;padding: 0 13px;">
        <div style="height:15%">
          <div style="height:100%;">
            <Module4 ref="chart4" />
          </div>
        </div>
        <div style="height:16%;padding:6px 0;">
          <div style="height:100%;">
              <Module5 ref="chart5" />
          </div>
        </div>
        <div style="height:60%">
          <Module6 ref="chart6" />
        </div>
        <div style="height: 9%;" class="bottom-bg">
          车辆实时监控
          <img
            src="./images/bottom-bg.png"
            class="bottom-bg-img"
            alt=""
            srcset=""
          />
        </div>
      </Col>
      <Col span="7" style="height:100%">
        <div style="height:48%">
          <div style="height: 100%;">
            <Module7 ref="chart7" />
          </div>
        </div>
        <div style="height:32%;padding:6px 0">
          <div style="height: 100%;">
            <Module8 ref="chart8"/>
          </div>
        </div>
        <div style="height:19%;">
          <div style="height: 100%;">
            <Module9 ref="chart9" />
          </div>
        </div>
      </Col>
    </Row>
  </div>
</template>

<script>
import Module1 from "./components/page0/module1.vue";
import Module2 from "./components/page0/module2.vue";
// import Module3 from "./components/page0/module3.vue";
import Module4 from "./components/page0/module4.vue";
import Module5 from "./components/page0/module5.vue";
import Module6 from "./components/page0/module6.vue";
import Module7 from "./components/page0/module7.vue";
import Module8 from "./components/page0/module8.vue";
import Module9 from "./components/page0/module9.vue";
export default {
  components: {
    Module1,
    Module2,
    // Module3,
    Module4,
    Module5,
    Module6,
    Module7,
    Module8,
    Module9
  },
  data() {
    return { resizeFn: null };
  },
  computed: {},
  methods: {},
  mounted() {
    this.resizeFn = this.$debounce(() => {
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      for (let i = 1; i < 10; i++) {
        this.$refs["chart" + i].setChart && this.$refs["chart" + i].setChart();
      }
    }, 500);
    window.addEventListener("resize", this.resizeFn);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeFn);
  },
};
</script>

<style lang="less" scoped>
.page0 {
  min-height: 100%;
  height: 100%;
  width: 100%;
  padding: 0px 20px 0px;
  background: #061e3e;
  // overflow: auto;
  overflow: hidden;

  .bottom-bg {
    position: relative;
    color: #66ffff;
    font-size: 23px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // transform: translateY(50%);

    &-img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scale(0.7) translateY(10%);
      bottom: 0;
    }
  }
}
</style>
